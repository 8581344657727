<template>
  <div>
    <import-payments-totalizer :filters="filters" ref="totalizer"/>

    <!-- Filters -->
    <b-card no-body class="mb-0">
      <div>
        <b-card-header class="pb-50">
          <h5>Filtros</h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="6" class="mb-1">
                <dynamic-select
                  id="customerName"
                  label="Cliente"
                  placeholder="Digite o nome do cliente"
                  v-model="customer"
                  :options="customers"
                  :loading="loading.customers"
                  @find="findCustomers"
                />
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Empresa" label-for="searchPartnerCompany">
                <all-select
                  id="searchPartnerCompany"
                  v-model="partnerCompany"
                  :options="partnerCompanies"
                  label="name"
                  take-this="id"
                  track-by="id"
                  multiple
                  :close-on-select="false"
                  :loading="loadingPartnerCompanies"
                ></all-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Produto" label-for="searchProduct">
                <all-select
                  id="searchProduct"
                  v-model="product"
                  :options="products"
                  label="name"
                  take-this="id"
                  track-by="id"
                  multiple
                  :close-on-select="false"
                  :loading="loadingProducts"
                ></all-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label class="mb-0">Exibir</label>
            <v-select v-model="itemsPerPage" dir="ltr" :options="itemsPerPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" @input="search(currentPage)">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-button
                variant="outline-primary"
                :disabled="loading || loadingReport"
                class="mr-1"
                @click="downloadReport"
              >
                <b-spinner small v-if="loadingReport" />
                <feather-icon v-else icon="DownloadIcon" class="mr-50" />
                {{ loadingReport ? "Aguarde..." : "Provisionados não pagos" }} 
              </b-button>

              <b-button
                variant="primary"
                :disabled="loading"
                @click="search(currentPage)"
              >
                <feather-icon icon="SearchIcon" class="mr-50" />
                Pesquisar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas importações para esta busca"
        :busy="loading"
      >
       <!-- Column: Cliente -->
       <template #cell(customer_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.customer_name">
              <b-button
                :id="`customer-import-pop-icon-${data.item.id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <user-detail-popover
                :target="`customer-import-pop-icon-${data.item.id}`"
                type="customer"
                :id="data.item.customer_id"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.customer_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: ID contrato -->
        <template #cell(contract_id)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              <b-button
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: #b4b7bd"
                @click="detailsNewTab(data.item.contract_id)"
              >
                {{ data.item.contract_id }} 
                <feather-icon
                  size="16"
                  icon="ExternalLinkIcon"
                  class="cursor-pointer"
                />
              </b-button>
            </span>
          </div>
        </template>
        <!-- Column: solução financeira -->
        <template #cell(financial_solution_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.financial_solution_name">
              <b-button
                :id="`financial-solution-import-pop-${data.item.id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <financial-solution-popover
                :target="`financial-solution-import-pop-${data.item.id}`"
                :id="data.item.financial_solution_id"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.financial_solution_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Valor importado -->
        <template #cell(amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.amount | toCurrency
            }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BForm,
  BPagination
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ImportPaymentsSidebar from "../components/ImportPaymentsSidebar";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import FinancialSolutionPopover from "@/modules/contract/components/FinancialSolutionPopover";
import ImportPaymentsTotalizer from "../components/ImportPaymentsTotalizer";
import AllSelect from "@/modules/shared/components/AllSelect";
import DynamicSelect from '@/modules/shared/components/DynamicSelect';
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as importStatus from "@/constants/financial_import_status";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BForm,
    BSpinner,
    BFormGroup,
    BPagination,
    ImportPaymentsSidebar,
    vSelect,
    UserDetailPopover,
    FinancialSolutionPopover,
    ImportPaymentsTotalizer,
    AllSelect,
    DynamicSelect
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      importStatus,
      loading: false,
      loadingCustomers: false,
      loadingProducts: false,
      loadingPartnerCompanies: false,
      loadingReport: false,
      tableColumns: [
        { key: "customer_name", label: "Cliente"},
        { key: "contract_id", label: "ID do contrato" },
        { key: "financial_solution_name", label: "Solução financeira" },
        { key: "amount", label: "Valor importado" }
      ],
      currentPage: 1,
      itemsPerPage: 25,
      customer: undefined,
      partnerCompany: undefined,
      product: undefined
    };
  },
  computed: {
    ...mapGetters({
      customers: sharedTypes.CUSTOMERS,
      searchResult: types.PAYMENTS_IMPORTS_DETAILS_LIST,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      products: sharedTypes.PRODUCTS,
      partnerCompanies: sharedTypes.PARTNER_COMPANIES
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    filters: function () {
      return {
        partner_company: this.partnerCompany,
        product: this.product
      }
    }
  },
  mounted() {
    this.search()
    this.loadingProducts = true;
    this.getProducts({ has_financial_solution: 0 })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os produtos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingProducts = false;
      });
    this.loadingPartnerCompanies = true;
    this.getPartnerCompanies({ has_financial_solution: 0 })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as empresas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingPartnerCompanies = false;
      });

  },
  methods: {
    ...mapActions({
      getCustomers: sharedTypes.GET_CUSTOMERS,
      searchPaymentDetails: types.GET_PAYMENTS_IMPORTS_DETAILS_LIST,
      getProducts: sharedTypes.GET_PRODUCTS,
      getPartnerCompanies: sharedTypes.GET_PARTNER_COMPANIES,
      downloadProvisionedReport: types.DOWNLOAD_PAYMENTS_PROVISIONED_REPORT
    }),
    findCustomers(keyword) {
      this.loadingCustomers = true
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingCustomers = false;
        });
    },
    findCustomers(keyword) {
      this.loadingCustomers = true
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingCustomers = false;
        });
    },
    search(currentPage){
      this.loading = true
      this.searchPaymentDetails({
        ...this.filters,
        customerId: this.customer,
        year: parseInt(this.$route.params.year),
        month: parseInt(this.$route.params.month),
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,        
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as importações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
      this.$refs.totalizer.search()
    },
    detailsNewTab(id) {
      var routeData = this.$router.resolve({
        name: "contract-details-summary",
        params: { id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    downloadReport(){
      this.loadingReport = true
      this.downloadProvisionedReport({
        year: parseInt(this.$route.params.year),
        month: parseInt(this.$route.params.month)
      })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loadingReport = false
        })
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
</style>
